import countryList from 'static/countries-states/country.json';
import stateList from 'static/countries-states/state.json';

const compare = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

function getStatesOfCountry(countryCode) {
  const states = stateList.filter(value => {
    return value.countryCode === countryCode;
  });
  return states.sort(compare);
}

function getAllCountries() {
  return countryList;
}

export { getAllCountries, getStatesOfCountry };
